const initialState = {
  item: {
    prolongacion_cliente: {
      cuentas_pagar: 0,
      reten_liberadas: 0,
    },
  },
  id_credito: null,
  pro_ejec: [],
  respuestas: [],
  comite_ejecutivos: [],
  step: 1,
  prolongaciones_historicas: [],
  open_print_modal: false,
  giro: false,
  form_options: {
    payment_options: [],
    cuentas: [],
  },
};

const prolongaciones = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ID_CREDITO':
      return { ...state, id_credito: action.data };
    case 'SET_PRO_EJEC':
      return { ...state, pro_ejec: action.data };
    case 'SET_RESPUESTAS':
      return { ...state, respuestas: action.data };
    case 'SET_STEP_FORM_PROLONGACION':
      return { ...state, step: action.data };
    case 'SET_ITEM_PROLONGACION':
      return { ...state, item: action.data };
    case 'SET_COMITE_EJECUTIVOS':
      return { ...state, comite_ejecutivos: action.data };
    case 'SET_OPEN_PRINT_MODAL':
      return { ...state, open_print_modal: action.data };
    case 'SET_PROLONGACIONES_HISTORICAS':
      return { ...state, prolongaciones_historicas: action.data };
    case 'SET_GIRO_PROLONGACION':
      return { ...state, giro: action.data };
    case 'SET_OPTIONS_PROLONGACION':
      return { ...state, form_options: action.data };
    default:
      return { ...state };
  }
};

export default prolongaciones;
