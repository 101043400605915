const initialState = {
  shouldReloadSaleList: false,
  shouldReloadPagaresList: false,
  form_options: {
    signers: [],
    buyers: [],
  },
  manageSaleSummary: {
    selectedTotalAmount: 0,
    selectedCount: 0,
    pagaresCount: 0,
  },
  saleCreationSummary: {
    selectedTotalAmount: 0,
    selectedCount: 0,
    pagaresCount: 0,
  },
  salesList: [],
};

const ventaPagares = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHOULD_RELOAD_SALE_LIST':
      return { ...state, shouldReloadSaleList: action.data };
    case 'SET_SHOULD_RELOAD_PAGARES_LIST':
      return { ...state, shouldReloadPagaresList: action.data };
    case 'SET_OPTIONS_VENTA_PAGARES':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_MANAGE_SALE_SUMMARY':
      return { ...state, manageSaleSummary: { ...state.manageSaleSummary, ...action.data } };
    case 'SET_SALE_CREATION_SUMMARY':
      return { ...state, saleCreationSummary: { ...state.saleCreationSummary, ...action.data } };
    case 'SET_SALES_LIST':
      return { ...state, salesList: action.data };
    default:
      return state;
  }
};

export default ventaPagares;
